import React, { useState } from 'react';
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import {toAbsoluteUrl} from "../../_helpers";
import { FaExternalLinkAlt} from "react-icons/fa";
import {Modal,Button} from "react-bootstrap";
import VideoIframe from "../widgets/tiles/VideoIframe";
import { MainImagePopup } from '../widgets/tiles/MainImagePopUp';
import { round } from 'lodash';


export default function ExpandedDetais({row,userinfo}){
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
// console.log(userinfo.type)
    const [showVideo, setShowVideo] = React.useState(false);
    const [imageBroken, setImageBroken] = React.useState(false);
    const [imageBrokenAgain, setImageBrokenAgain] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const [heartBroken, setHeartBroken] = React.useState(false);
    const [arrowBroken, setArrowBroken] = React.useState(false);
    const [assetBroken, setAssetBroken] = React.useState(false);
    const GenerateTitle = (row) => {
        let title = ""
     
        if(row.C_Shape){
            title += " "
            title += row.C_Shape
        }
        if(row.C_Weight){
            title += " "
            title += row.C_Weight
        }
       
        if(row.C_Color){
            title += " "
            if(row.C_Color === "fancy"){
                title += row.f_intensity
                title += " "
                title += row.f_overtone
                title += " "
                title += row.f_color
            } else{
                title += row.C_Color
            }
        }
        if(row.C_Clarity){
            title += " "
            title += row.C_Clarity
        }

        if(row.C_Cut){
            title += " "
            title += row.C_Cut
        }
       
        if(row.C_Polish){
            title += " "
            title += row.C_Polish
        }

        if(row.C_Symmetry){
            title += " "
            title += row.C_Symmetry
        }

        if(row.C_Fluorescence){
            title += " "
            title += row.C_Fluorescence
        }
        return title
    }
    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

    function openVideoModal() {
        setShowVideo(true)
    }

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }


    const [mainimageclick,setMainImageClick] = useState(false)
  
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function GetRatio(row) {
        let ratioval
        if (row.C_Shape != 'ROUND') {
            if (row.C_Length >= row.C_Width) {
                ratioval = round(row.C_Length / row.C_Width,2)
            } else if (row.C_Length < row.C_Width) {
                ratioval = round(row.C_Width / row.C_Length ,2)
            } else if (row.C_Shape == 'HEART') {
                ratioval = round(row.C_Length / row.C_Width ,2)
            } else {
                ratioval = '-';
            }
        } else {
            ratioval = '-';
        }
        // console.log(ratioval)
        return ratioval
    }
    function discount(row){
        
        let orignal_price = round(row.O_Rate * row.C_Weight, 2);
        let carat_price = round(orignal_price / row.C_Weight, 2);
        let discount = row.raprate ?
            round((carat_price - row.raprate) / (row.raprate) * 100,2)
            : parseInt(row.C_Rap) ?
                 round((carat_price - row.C_Rap) / (row.C_Rap) * 100,2) : "0.00"
        // console.log(parseFloat(discount).toFixed(2))
       return parseFloat(discount).toFixed(2);
        // $discount_main 	= !empty($value->raprate) ? round(($carat_price - $value->raprate) / $value->raprate * 100, 2) : 0;
    }
    return(
       <>
        <div className={`expandedDetails ${row.C_Color ==="fancy" || row.C_Color ==="fancy" || row.C_Color === "" || row.C_Color === null || row.C_Color === "Fancy" || row.supplier_comment ? "fancyExpand" : "" }`} >
            <div className="row pt-2 m-0">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                        <li className="img_thumb">
                            {row.image && row.image != "null" ? (
                                <div>
                                <MainImagePopup Image={row.image} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                { imageBroken ? 
                                    <>
                                        {imageBrokenAgain ?  
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                onError={() => setImageBrokenAgain(true)}
        
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${row.C_Shape.toLowerCase()}-no.png`
                                                )}
                                            /> 
                                        : 
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                src={toAbsoluteUrl(
                                                    `/media/shape/noimage1.jpg`
                                                )}
                                                onError={e => {
                                                    e.currentTarget.src = toAbsoluteUrl(
                                                        `/media/shape/noimage.jpg`
                                                    )
                                                }}
                                            /> 
                                        }
                                    </>
                                :
                                    <img
                                        className="img-fluid cursor-pointer"
                                        alt={row.C_Shape}
                                        src={row.image}
                                        onClick={() => setMainImageClick(true)}
                                        onError={() => setImageBroken(true)}

                                    />
                                }
                                </div>
                            ) : (
                                <div>
                                    <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row.C_Shape.toLowerCase()}-no.png` )} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                <img
                                    className="img thumb"
                                    alt="shape"
                                    src={toAbsoluteUrl( `/media/shape/${row.C_Shape.toLowerCase()}-no.png` )}
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/noimage.jpg`
                                        )
                                    }}
                                />
                                </div>
                            )}
                        </li>
                        {row.video  ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openVideoModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                            </li>
                        ):('')}
                        {row.heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                            </li>
                            
                        ):('')}
                        {row.arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                            </li>
                        ):('')}
                        {row.asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4">
                        {row.image && row.image != "null" ? (
                            <div>
                                <MainImagePopup Image={row.image} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                { imageBroken ? 
                                    <>
                                        {imageBrokenAgain ?  
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                onError={() => setImageBrokenAgain(true)}
        
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${row.C_Shape.toLowerCase()}-no.png`
                                                )}
                                            /> 
                                        : 
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                src={toAbsoluteUrl(
                                                    `/media/shape/noimage1.jpg`
                                                )}
                                            /> 
                                        }
                                    </>
                                :
                                    <img
                                        className="img-fluid cursor-pointer"
                                        alt={row.C_Shape}
                                        src={row.image}
                                        onClick={() => setMainImageClick(true)}
                                        onError={() => setImageBroken(true)}

                                    />
                                }
                            </div>
                        ) : (
                            <div>
                                <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row.C_Shape.toLowerCase()}-no.png` )} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                            <img
                                className="img-fluid"
                                alt="shape"
                                src={toAbsoluteUrl(
                                    `/media/shape/${row.C_Shape.toLowerCase()}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                            />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 my-3 d-flex align-items-center">
                            <div className="mr-2"> 
                                {row.country ? (
                                    <OverlayTrigger
                                        placement="top"
                                        className="text-capitalize"
                                        overlay={<Tooltip id="tooltip">{row.country}</Tooltip>}
                                    >
                                        <img
                                            className="img-fluid flagIco"
                                            alt={row.country}
                                            src={toAbsoluteUrl(`/media/map/${row.country.toLowerCase()}.png`)}
                                        />
                                    </OverlayTrigger>
                                ) : (
                                    null
                                )}
                            </div >
                            
                            <div className="mr-2 border-right text-primary">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        row.Lab === "IGI"
                                            ? `https://www.igi.org/viewpdf.php?r=${row.Certi_NO}`
                                            : row.Lab === "GIA"
                                            ? `https://www.gia.edu/report-check?reportno=${row.Certi_NO}`
                                            : row.Lab === "HRD"
                                            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row.Certi_NO}`
                                            : row.Lab === "GCAL"
                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row.Certi_NO}`
                                            : row.Certi_link
                                    }
                                >
                                    {row.Lab} : {row.Certi_NO} <FaExternalLinkAlt />
                                </a>
                            </div>
                            <div >Stone ID: <div className="text-primary d-inline-block mr-2">{row.Loat_NO}</div></div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h4 font-weight-bold text-capitalize">{GenerateTitle(row)}</span>
                        </div>
                        <div className="col-12 mt-5">
                            <span className="text-muted font-weight-bold font-size-sm">$/CT : ${parseFloat(row.O_Rate).toFixed(2)}</span>
                            <div className='d-flex align-items-center' ><span className="font-size-h2 font-weight-bold mb-0 d-block">${parseFloat(row.O_Rate * row.C_Weight).toFixed(2) }</span> <span className={`${discount(row) >= 0  ? 'text-success' : 'text-danger'} pl-2`}> {discount(row)}</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row.C_Color ==="fancy" || row.C_Color === "" || row.C_Color === null || row.C_Color === "Fancy") ? (
                                <>
                                    <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 font-weight-bold">  {row.f_intensity ? row.f_intensity : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 font-weight-bold">  {row.f_overtone ? row.f_overtone : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 font-weight-bold">  {row.f_color ? row.f_color : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">Parameter Details </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">Measurement</div>
                                <div className="col-6 font-weight-bold"> {parseFloat(row.C_Length).toFixed(2)} * {parseFloat(row.C_Width).toFixed(2)} * {parseFloat(row.C_Depth).toFixed(2)} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 ">Table (%) </div>
                                <div className="col-6 font-weight-bold"> {row.C_TableP ? parseFloat(row.C_TableP).toFixed(2) : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Depth (%)</div>
                                <div className="col-6 font-weight-bold"> {row.C_DefthP ? parseFloat(row.C_DefthP).toFixed(2) : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">CR.Height</div>
                                <div className="col-6 font-weight-bold">{row.Crn_Ht ? row.Crn_Ht : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">CR.Angle</div>
                                <div className="col-6 font-weight-bold">{row.Crn_Ag ? row.Crn_Ag : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">PAV.Height</div>
                                <div className="col-6 font-weight-bold">{row.Pav_Dp ? row.Pav_Dp : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">PAV.Angle</div>
                                <div className="col-6 font-weight-bold">{row.Pav_Ag ? row.Pav_Ag : "-"}</div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">Ratio</div>
                                <div className="col-6 font-weight-bold"> {row.Ratio?parseFloat(row.Ratio).toFixed(2):"-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Girdle %</div>
                                <div className="col-6 font-weight-bold">{row.gridle_per ? row.gridle_per : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Girdle Condition</div>
                                <div className="col-6 font-weight-bold">{row.gridle  ? row.gridle : "-"}</div>
                            </div>
                        </div>
                        <div className="col-6 border-left">
                            <h5 className="font-size-h5 text-dark font-weight-bolder mb-3  mt-5">Additional Details</h5>
                            <div className="row mb-1">
                                <div className="col-6">City</div>
                                <div className="col-6 font-weight-bold">{row.city ? row.city : "-"}</div>
                            </div>
                            { userinfo.type != "Natural" ? "" :
                                <div className="row mb-1">
                                    <div className="col-6">Brand</div>
                                    <div className="col-6 font-weight-bold">{row.canada_mark ? row.canada_mark : "-"}</div>
                                </div>
                            }
                            <div className="row mb-1">
                                <div className="col-6">Shade</div>
                                <div className="col-6 font-weight-bold">{row.shade  ? row.shade : "-"}</div>
                            </div>
                            <hr className="w-75 ml-0"/>
                            <div className="row mb-1">
                                <div className="col-6">Milky</div>
                                <div className="col-6 font-weight-bold">{row.Milky  ? row.Milky : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Eye Clean</div>
                                <div className="col-6 font-weight-bold">{row.EyeC ? row.EyeC : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Culet</div>
                                <div className="col-6 font-weight-bold"> {row.cutlet  ? row.cutlet : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Key to Symbol</div>
                                <div className="col-6 font-weight-bold">{row.Key_Symbols ? row.Key_Symbols : "-" }</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Availability </div>
                                <div className="col-6 font-weight-bold"> {row.availability ? row.availability : "-"}</div>
                            </div>
                            {userinfo.type != "Natural" ? 
                                <>
                                    <div className="row mb-1">
                                        <div className="col-6"> Growth Type </div>
                                        <div className="col-6 font-weight-bold">  {row.brown ? row.brown : "-"} </div>
                                    </div>
                                    {row.lab_treat ? 
                                        <div className="row mb-1">
                                            <div className="col-6"> Treatment</div>
                                            <div className="col-6 font-weight-bold">  {row.lab_treat === "Treated"?"Post Growth Treatment":row.lab_treat} </div>
                                        </div>
                                    : ""}
                                    {row.green ?
                                        <div className="row mb-1">
                                            <div className="col-6"> Treatment </div>
                                            <div className="col-6 font-weight-bold">  {row.green ? row.green : "-"} </div>
                                        </div>
                                    : "" }
                                </>
                            :   
                                <>
                                    <div className="row mb-1">
                                        <div className="col-6"> Origin </div>
                                        <div className="col-6 font-weight-bold">  {row.brown ? row.brown : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6"> Treatment </div>
                                        <div className="col-6 font-weight-bold">  {row.green ? row.green : "-"} </div>
                                    </div>
                                </>
                            }
                            { row.supplier_comment ?
                                <div className="row mx-0 mt-5">
                                    <div className="col-12 pt-3 px-0 border-top">
                                        <p className="font-size-h4 font-weight-bold text-primary mb-3">Supplier Comment</p>
                                        <div className="row mb-1">
                                    <div className="col-12">{row.supplier_comment}</div>
                                </div>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            show={showHeartImg}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Body className="text-center">
                {heartBroken ?
                    <img  src={toAbsoluteUrl(`/media/shape/${row.C_Shape.toLowerCase()}-no.png`)} alt={row.C_Shape} className="img-fluid" />
                :
                    <img src={row.heart} alt={row.C_Shape} onError={()=>setHeartBroken(true)} className="img-fluid" />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showArrowImg}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Body className="text-center">
                {arrowBroken ?
                    <img src={toAbsoluteUrl(`/media/shape/${row.C_Shape.toLowerCase()}-no.png`)} alt={row.C_Shape} className="img-fluid" />
                :
                    <img src={row.arrow} alt={row.C_Shape} onError={()=> setArrowBroken(true)} className="img-fluid" />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showAssetImg}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Body className="text-center">
                {assetBroken ?
                    <img src={toAbsoluteUrl(`/media/shape/${row.C_Shape.toLowerCase()}-no.png`)} alt={row.C_Shape} className="img-fluid" />
                :
                    <img src={row.asset} alt={row.C_Shape}  onError={()=> setAssetBroken(true)}  className="img-fluid" />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                Close
                </Button>
            </Modal.Footer>
        </Modal>
        <VideoIframe
            showVideo={showVideo}
            handleClose={handleClose}
            src={row.video }
        />
      </>
    )
}